import { IAboutData } from "Models/AboutData";
import { IGeneralInfo } from "Models/GeneralInfo";
import { INewsMessage } from "Models/NewsMessage";
import firebase from 'firebase/compat/app';
import moment from "moment";

export const generalInfoConverter = {
    toFirestore: function (generalInfo: IGeneralInfo) {
        return {
            header: generalInfo.header,
            title: generalInfo.title,
            subtitle: generalInfo.subtitle,
            descriptionHeader: generalInfo.descriptionHeader,
            description: generalInfo.description,
            openingHours: generalInfo.openingHours,
            closingHours: generalInfo.closingHours,
            banners: generalInfo.banners,
        };
    },
    fromFirestore: function (snapshot: any, options: any): IGeneralInfo {
        const data = snapshot.data(options);
        return {
            header: data.header,
            title: data.title,
            subtitle: data.subtitle,
            descriptionHeader: data.descriptionHeader,
            description: data.description,
            openingHours: data.openingHours,
            closingHours: data.closingHours,
            banners: data.banners
        };
    }
};

export const aboutDataConverter = {
    toFirestore: function (aboutData: IAboutData) {
        return {
            content: aboutData.content,
        };
    },
    fromFirestore: function (snapshot: any, options: any): IAboutData {
        const data = snapshot.data(options);
        return {
            content: data.content,
        };
    }
}

export const newsMessageConverter = {
    toFirestore: function (newsMessage: INewsMessage) {
        return {
            addedOn: momentToTimestamp(newsMessage.addedOn),
            layout: newsMessage.layout,
            title: newsMessage.title,
            message: newsMessage.message,
            imageOneUrl: newsMessage.imageOneUrl,
        };
    },
    fromFirestore: function (snapshot: any, options: any): INewsMessage {
        const data = snapshot.data(options);
        return {
            id: snapshot.id,
            addedOn: timestampToMoment(data.addedOn),
            layout: data.layout,
            title: data.title,
            message: data.message,
            imageOneUrl: data.imageOneUrl,
        };
    }
}

function timestampToMoment(timestamp: firebase.firestore.Timestamp): moment.Moment {
    let asDate = timestamp.toDate();
    return moment(asDate);
}

function momentToTimestamp(momentValue: moment.Moment): firebase.firestore.Timestamp {
    return new firebase.firestore.Timestamp(momentValue.unix(), 0);
}