import React, { useEffect } from "react";
import { AppBar, Box, Button, Divider, Drawer, Link, Toolbar, Typography } from "@mui/material";
import { ArrowForwardIos, Menu as MenuIcon } from "@mui/icons-material";
import logo from 'Images/logo_image.png';
import { useNavigate } from "react-router-dom";
import FireStoreHelper from "Helpers/FireStore/FireStoreHelper";

const menuItems = [
    { text: <span>Home</span>, path: '/' },
    { text: <span>Nieuws</span>, path: '/Nieuws' },
    // { text: <span>Producten</span>, path: '/producten' },
    // { text: <span>Top-100 Films</span>, path: '/producten/film' },
    // { text: <span>Over ons</span>, path: '/over' },
    { text: <span>Contact & Openingstijden</span>, path: '/contact-openingstijden' },
]

interface IProps {
    IsHomeTop: boolean;
}

export default function Menu(props: IProps) {
    const { IsHomeTop } = props;
    const navigation = useNavigate();

    const [open, setOpen] = React.useState(false);
    const [headerTitle, setHeaderTitle] = React.useState<string>();
    
    useEffect(() => {
        initialize();
    }, []);

    async function initialize() {
        var result = await FireStoreHelper.GetGeneralInfo();
        setHeaderTitle(result?.header);
    }

    let numberOfYearClicks = 0
    function OnYearClick() {
        ++numberOfYearClicks;
        if (numberOfYearClicks >= 10) {
            window.location.href = '/beheer';
        }
    }

    return (
        <>
            <AppBar component='nav' sx={IsHomeTop ? { boxShadow: 'none', backgroundColor: 'transparent', transition: 'background-color 0.3s, box-shadow 0.3s' } : { transition: 'background-color 0.3s, box-shadow 0.3s' }}>
                <Toolbar>
                    <Link href={IsHomeTop ? undefined : '/'} sx={{ opacity: IsHomeTop ? '0' : '100', cursor: IsHomeTop ? 'default' : 'pointer', transition: 'opacity 0.3s' }}><img height={48} src={logo} /></Link>
                    <Typography sx={{ fontSize: { xs: 24, sm: 28, md: 36, lg: 48 }, flexGrow: 1, opacity: IsHomeTop ? '0' : '100', cursor: IsHomeTop ? 'default' : 'pointer', transition: 'opacity 0.3s' }}  marginLeft={2} variant="h4" textAlign='start' fontFamily="'Atomicaboy', sans-serif">
                        <Link href={IsHomeTop ? undefined : '/'} color='#fff' underline='none'>{headerTitle}</Link>
                    </Typography>
                    <Box
                        disableRipple
                        component={Button}
                        color='white'
                        fontSize={18}
                        onClick={() => setOpen(true)}
                        minWidth={82}
                        endIcon={<Box width={28} height={28} component={MenuIcon} />}>
                        Menu
                    </Box>
                </Toolbar>
            </AppBar>
            <Drawer
                anchor='right'
                open={open}
                onClose={() => setOpen(false)}>
                <Box display='flex' flexDirection='column' justifyContent='space-between' height='100%' maxWidth='90vw'>
                    <Box display='flex'justifyContent='right'>
                        <Box
                            disableRipple
                            sx={{ '&:hover': { 'backgroundColor': 'transparent'} }}
                            bgcolor='transparent'
                            component={Button}
                            fontSize={18}
                            onClick={() => setOpen(false)}
                            color='black'
                            fontWeight='800'
                            pt={2}
                            ml={1}
                            endIcon={<Box width={28} height={28} component={ArrowForwardIos} />}>
                            Sluiten
                        </Box>
                    </Box>
                    {menuItems.map(mi => 
                        <Link mx={6} underline='hover' href={mi.path} key={mi.path}>
                            <Typography fontSize={24}>{mi.text}</Typography>
                        </Link>
                    )}
                    <div>
                        <Divider />
                        <Box p={1}>
                            <Typography fontSize={12}>{headerTitle} - <span onClick={OnYearClick}>2024</span></Typography>
                            <Typography fontSize={10}>Gesponsord door <a target="_blank" href="https://www.voertuigvinder.nl">voertuigvinder.nl</a></Typography>
                        </Box>
                    </div>
                </Box>
            </Drawer>
        </>
    );
}